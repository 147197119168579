.forget-password-container {
  text-align: center;
  margin: auto;
  box-shadow: 5px 10px 8px #888888;
  border: 1px solid white;
  margin-top: 10%;
  width: 700px;
  /* margin-left: 31%; */
  background: var(--surface-card);

}

.forgot-password-inputtext {
  width: 70%;
}

.forget-password {
  width: 400px;
}

.forget-password-logo {
  margin: 3%;
}
.demo-work-carousel {
  border: 12px solid #3f72af;
  margin-top: 1%;
  border-radius: 4%;
}

.demo-slide-image {
  width: 103%;
  height: 59vh;
}

h4.demo-title-heading {
  margin-top: 0%;
  font-weight: 500;
}

.caro-demo .p-carousel-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 59vh;
  margin-top: 2%;
}


@media screen and (max-width: 1920px) {
  .demo-work-carousel {
    width: 60%;
    height: 70vh;
  }
}

@media screen and (max-width: 1111px) {
  .demo-work-carousel {
    width: 86%;
    height: 71vh;
  }

  .caro-demo .p-carousel-container {
    height: 52vh;
  }
}

@media screen and (max-width: 960px) {
  .demo-work-carousel {
    width: 100%;
    height: 65vh;
  }

  .caro-demo .p-carousel-container {
    height: 52vh;
  }
}

@media screen and (max-width: 1536px) {
  .caro-demo .p-carousel-container {
    height: 58vh;
  }
}

@media screen and (max-width: 768px) {
  .demo-work-carousel {
    width: 100%;
    height: 67vh;
  }

  .caro-demo .p-carousel-container {
    height: 50vh;
  }
}

@media screen and (max-width: 500px) {
  .demo-work-carousel {
    width: 100%;
    height: 43vh;
  }

  .caro-demo .p-carousel-container {
    height: 50vh;
  }

  .about-div-home {
    height: 65vh;
  }

  .demo-slide-image {
    width: 100%;
    height: 34vh;
  }
}

@media screen and (max-width: 444px) {
  .demo-work-carousel {
    width: 100%;
    height: 43vh;
  }

  .caro-demo .p-carousel-container {
    height: 50vh;
  }

  .about-div-home {
    height: 65vh;
  }

  .demo-slide-image {
    width: 100%;
    height: 34vh;
  }
}
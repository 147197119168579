.p-sidebar.p-component.user-sidebar.p-sidebar-right.p-sidebar-active.p-sidebar-enter-done {
  width: 22%;
  height: 100%;
}

.p-toast .p-toast-message .p-toast-message-content {
  z-index: 1000;
}

.p-sidebar .p-sidebar-header {
  padding: 1px;
  margin-top: 1%;
}

.p-sidebar-right .p-sidebar {
  width: 24rem !important;
}

.p-tabview .p-tabview-panels {
  width: 100%;
}

.classcolourterm {
  color: blue;
}

.login-btn-user {
  padding: 0px 0px 0px 1px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -3px;
}

@media (max-width: 991px) {
  button.p-button.p-component.user-btn {
    margin-left: 2.5%;
    margin-top: 0px;
    margin-bottom: 1px;
  }

  .login-btn-user {
    margin-top: 17px;
  }
}

.user-btn {
  display: flex;
  flex-direction: row;
}

.p-button.p-component.user-btn {
  margin: 0px 0px;
  margin-bottom: 7px;
  color: #ffffff;
  font-size: 1rem;
  background-color: var(--ps-blue);
  transition: #4a9dff 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 20px;
  margin-top: -5px;

}

.p-button.p-component.user-btn:hover {
  background: var(--ps-blue-hover);
  border-color: #0097ff61;
}

.card.flex.justify-content-center-button {
  display: inline;
}

.flex.flex-wrap.gap-2.mb-3 {
  align-self: center;
}

.m-0 {
  justify-content: center;
}

.head-one {
  font-size: 20px;
  margin: 0px 60px;
}

.p-inputtext {
  width: 100%;
}

.p-password {
  width: 100%;
}

#login_corp-btn {
  margin-top: 0px;
}

#login_forgotPass {
  margin-top: -10px;
  border: none;
}

a.login_after_nameID {
  margin-top: 10%;
  margin-left: -3%;
}

.p-button.login-btn {
  color: #ffffff;
  background-color: var(--ps-blue);
  border: 1px solid var(--ps-blue);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: #4a9dff 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
  width: 100%;
  margin-top: 6%;
}

.p-button.login-btn:hover {
  color: white;
  background: var(--ps-blue-hover);
  border: 1px solid var(--ps-blue);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  width: 100%;
}

.p-button.forgot-btn {
  color: black;
  background-color: white;
  border: 1px solid rgb(129, 127, 127);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  width: 100%;
}

.p-button.forgot-btn:hover {
  color: #ffffff;
  background: white;
  border: 1px solid rgb(129, 127, 127);
}

.error {
  color: red;
  margin-bottom: -3px;
}

.success-msg {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding: 20px 20px;
  font-size: 20px;
}

.sign-icon {
  font-size: 40px;
}

.loginUserIcon {
  width: 30px;
  height: 30px;
  top: 9px;
  position: relative;
  margin-left: 5px;
}

.nav-link-div {
  margin-top: 3px;
}

.classcolourterm {
  cursor: pointer;
}

.nav-link {
  position: relative;
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
}

.p-button.loginButton {
  margin: 0px 0px;
  margin-top: 2px;
  margin-bottom: 7px;
  color: #ffffff;
  font-size: 1rem;
  background: var(--ps-blue);
  border: 1px solid var(--ps-blue);
  transition: #4a9dff 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 20px;

}

.p-button.loginButton:hover {
  background: var(--ps-blue-hover);
  border: 1px solid var(--ps-blue-hover);
}

.nav-link>a {
  line-height: 3rem;
  margin-top: 14px;
  color: black;
  padding: 0 0.9rem;
  letter-spacing: 1px;
  font-size: 0.94rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.nav-link>a>i {
  margin-left: 0.2rem;
}

.dropdownT {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  position: absolute;
  z-index: 9999999 !important;
  left: -148px;
  background: white;
  border: 1px solid #dbe2ef;
  opacity: 0;
  pointer-events: none;
  width: 14em;
  margin-left: 39px;
}

.dropdownT-ul {
  position: relative;
  list-style: none;
  color: black;
}

.dropdownT-link>a {
  display: flex;
  background-color: #ffffff;
  color: #192739;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-left: -14.5%;
}

.dropdownT-link:hover>a {
  background: var(--ps-blue-hover);
  border-color: #0097ff61;
  color: #fff;
}

.dropdownT-link:first-child:hover~.arrow {
  background-color: #4a9dff;
}

.dropdownT-link {
  position: relative;
}

.dropdownT.second {
  top: 0;
  left: 100%;
  padding-left: 0.8rem;
  cursor: pointer;
}

.dropdownT.second .arrow {
  top: 10px;
  left: -5.5px;
}

.nav-link:hover>.dropdownT,
.dropdownT-link:hover>.dropdownT {
  opacity: 1;
  pointer-events: auto;
}

.signUpSubTab>div>div>ul {
  border: 0px solid #dee2e6 !important;
}

.privateTab>a:focus {
  box-shadow: inset 0 0 0 0rem #a6d5fa !important;
}

.companyTab>a:focus {
  box-shadow: inset 0 0 0 0rem #a6d5fa !important;
}

.companySignUpPostalCodeCity {
  display: flex;
  gap: 10px;
}

.phoneNumber {
  width: 100%;
}

.loginRebelSkoolLogo {
  text-align: center;
  margin-top: -7px;
}

.subTabRadioBtnLbl {
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.signUpSubTabRadioBtnDiv {
  display: flex;
  margin-bottom: 18px;
  gap: 24px;
}

a.user-greeting:hover {
  background-color: #3f72af;
  color: white;
}

.loginSignUpMandatoryStar {
  color: red;
  text-align: end;
  margin-bottom: 0px;
  font-size: 12px;
}

.p-tabview.p-component.flex.flex-wrap.gap-2.mb-3 {
  margin-top: -28px;
}

.captcha-input-box {
  height: 36px;
  margin-left: -52px;
  width: 160px;
}

.after-sign-up-verification {
  padding: 2%;
  margin-top: 5%;
}

.login_toast_success {
  margin-top: 80px;
}

a.login_after_nameID {
  margin-top: 2px;
}

.p-sidebar-right {
  justify-content: flex-end;
  z-index: 6 !important;
}

@media (max-width: 670px) {
  .loginRebelSkoolLogo {
    display: none;
  }
}

@media (max-width: 540px) {
  .login-btn-user {
    margin-top: -10px;
  }
}
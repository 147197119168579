.ID_Credits {
  width: 100vw;
  max-width: 1300px;
  min-height: 100vh;
  margin: 0px auto;
  overflow: hidden;
  padding: 70px 0;

  margin-top: 2%;
}

.credits-main {
  margin: 7%;
}

.credits-div {
  width: 100vw;
  max-width: 1300px;
  margin: 0px auto;
  overflow: hidden;
  padding: 70px 0;
  margin-top: 2%;
  margin-bottom: 5%;
}
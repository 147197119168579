.ID_About-Us {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  padding: 70px 0;
  /* margin-top: 2%; */
}

/* ------------------------------------------------------------------------- */

.cor-product {
  background-color: #cde2e2;
}

#cor-product-section {
  min-height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#cor-product-section h2 {
  color: rgb(56, 69, 86);
  font-size: 2rem;
  margin-bottom: 20px;
  position: relative;
}

#cor-product-section #cor-product-paragraph {
  font-size: 1rem;
  color: rgb(56, 69, 86);
  text-align: center;
  line-height: 1.5rem;
  white-space: 1px;
  text-transform: lowercase;
  border-radius: 10px;
  padding: 2% 1% 2% 1%;
}

/* -------------------------------------------------------------------- */

.corp-mapdiv {
  background-color: #fff;
  padding: 2rem 4rem 4rem 15rem;
}

.cor-iframe1 {
  width: 100%;
  height: 450px;
}

/* .addressMap {
  color: rgb(117, 122, 122);
} */

.corpMapMainDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#price-policy-div {
  background-color: white;
}

.corp-map-company-name,
.corp-map-location-name {
  font-weight: 500;
}

/* -------------------------------------------------------------------- */

@media screen and (max-width: 1920px) {
  #cor-product-section #cor-product-paragraph {
    width: 50%;
  }

  .cor-iframe1 {
    width: 600px;
    height: 450px;
  }
}

@media screen and (max-width: 1550px) {
  .corpMapMainDiv {
    margin-left: -6%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1450px) {
  .corpMapMainDiv {
    margin-left: -15%;
    display: grid;
  }
}

@media screen and (max-width: 1350px) {
  .corpMapMainDiv {
    margin-left: 2%;
  }

  .corp-mapdiv {
    background-color: #fff;
    padding: 0rem 0rem 0rem 0rem;
  }
}

@media screen and (max-width: 1200px) {
  .corp-map-Locationdiv {
    width: 95%;
    text-align: center;
    margin-top: 4%;
  }

  .corpMapMainDiv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 3%;
  }

}

@media screen and (max-width: 970px) {
  #cor-product-section #cor-product-paragraph {
    width: 75%;
  }
}

@media screen and (max-width: 700px) {
  .corpMapMainDiv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 99%;
  }

  .cor-iframe1 {
    width: 600px;
  }
}

@media screen and (max-width: 640px) {
  .cor-iframe1 {
    width: 550px;
  }
}

@media screen and (max-width: 590px) {
  .cor-iframe1 {
    width: 510px;
  }
}

@media screen and (max-width: 540px) {
  .corpMapMainDiv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 95%;
    text-align: center;
  }

  .cor-iframe1 {
    width: 450px;
  }
}

@media screen and (max-width: 510px) {
  .cor-iframe1 {
    width: 420px;
  }
}

@media screen and (max-width: 480px) {
  .cor-iframe1 {
    width: 400px;
  }

  #cor-product-section h2 {
    font-size: xx-large;
  }

  #cor-product-section #cor-product-paragraph {
    font-size: smaller;
  }
}

@media screen and (max-width: 430px) {
  .cor-iframe1 {
    width: 350px;
  }

  #cor-product-section h2 {
    font-size: x-large;
  }
}

@media screen and (max-width: 390px) {
  .cor-iframe1 {
    width: 300px;
  }
}

@media screen and (max-width: 375px) {
  .cor-iframe1 {
    width: 280px;
  }

  #cor-product-section h2 {
    font-size: x-large;
  }
}

@media screen and (max-width: 315px) {
  .cor-iframe1 {
    width: 250px;
  }
}

@media screen and (max-width: 310px) {
  .cor-iframe1 {
    width: 250px;
  }

  #cor-product-section h2 {
    margin-top: 8%;
  }
}
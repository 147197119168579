.corp-navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 69px;
  width: 100%;
  padding: 0.5rem 0rem;
  background: white;
  color: black;
  list-style: none;
  transition: 0.3s ease-in;

}

.corp-navigation-menu {
  margin-left: 30%;
  margin-top: 1%;
}

.corp-navigation-menu-ul {
  display: flex;
  padding: 0;
  list-style: none;
}

.corp-navigation-menu li {
  list-style-type: none;
  margin: 0 0.1rem;
  font-size: 18px;
}

.corp-navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #dbe2ef;
  width: 10px !important;
  height: 10px !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50% !important;
  padding: 0rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #007cdf !important;
  padding: 0rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #242527 !important;
  padding: 0rem;
}

.about-div-home {
  border: 1px solid white;
  background: #b2cbf9;
  width: 100%;
  height: 95vh;
}

/* -------------------------------------------------------------------------------------------------- */

.hero-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

p.hero-para {
  margin-left: 13%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.hero-para-h3 {
  font-size: 20px;
  /* font-weight: 600; */
  display: flex;
  flex-wrap: wrap;

}

.corp-price-tag-color {
  color: red;
  text-align: center;
  margin: 1% 37% 0% 37%;
  font-size: 27px;
  font-weight: 600;
}

.digital-main-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 1%;
}

.hero-content {
  position: absolute;
  width: 48%;
  top: 32%;
  left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(42, 40, 40);
  /* background-color: rgba(144, 131, 131, 0.1); */
  left: 45%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.hero-container>h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container>p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;

}

.hero-btns {
  margin-top: 20px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

/* ------------------------------------------------------------------------------------ */

.cor-services {
  margin: 5%;
}

#cor-service-section {
  min-height: 850px;
  width: 100%;
  background: #afaaaa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#cor-service-section h2 {
  color: rgb(56, 69, 86);
  font-size: 2rem;
  margin-bottom: 20px;
  position: relative;
}

#cor-service-section #cor-paragraph {
  width: 100%;
  font-size: 1rem;
  color: rgb(56, 69, 86);
  text-align: center;
  line-height: 1.5rem;
  white-space: 1px;
  text-transform: lowercase;
  border-radius: 10px;
  padding: 2% 1% 2% 1%;
}

.cor-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  z-index: 9;
  margin-top: 20px;
}

.cor-cards .cor-card {
  height: 350px;
  width: 450px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 8%;
  background: #dadcde;
  position: relative;
  border-radius: 10px;
  margin: 0 10px;
}

.cor-content h3 {
  text-align: center;
}

.cor-cards .cor-card i {
  font-size: 2.5rem;
  color: var(--primery-color);
  margin-bottom: 10px;
}

.cor-cards .cor-card h3 {
  font-size: 1.8rem;
  color: rgb(56, 69, 86);
  margin-bottom: 10px;
}

.cor-cards .cor-card .cor-content {
  color: #090909;
  font-size: 1.05rem;
  margin-bottom: 20px;
  font-weight: 500;
}

/* ======================== */

#services {
  padding: 8rem 0 3rem;
  background-color: #d8d8d8;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    min-height: 1005;
    left: 0;
    right: 0;
    z-index: 1;
    background: var(--dark-layer-color);
    pointer-events: none;
  }

  @media (max-width: 992px) {
    padding: 3rem 0;
  }

  .title_headling {
    text-align: center;
    max-width: 50rem;
    margin: auto;
    position: relative;
    z-index: 2;

    & h3 {
      width: 90%;
      font-size: 2.5rem;
      line-height: 3.214rem;
      font-weight: 700;
      color: var(--white-color);
      margin-bottom: 0;

      @media (max-width: 767px) {
        width: 100%;
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }

    & p {
      width: 90%;
      font-size: 1.214rem;
      line-height: 2rem;
      color: var(--font-color);

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .service_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5rem;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .service_box {
      width: calc(100% / 3 - 2rem);
      padding: 2.8571rem 2.1429rem;
      border-radius: 1.4286rem;
      background: var(--bg-dark-color);
      box-sizing: border-box;
      margin-bottom: 3rem;
      position: relative;
      z-index: 3;

      @media (max-width: 992px) {
        width: calc(100% / 2 - 1rem);
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      .number {
        position: absolute;
        font-size: 4.2857rem;
        line-height: 4.2857rem;
        right: 2.8571rem;
        top: 2.1429rem;
        opacity: 0.07;
        margin: 0;
        transition: 0.3s ease-in-out;
      }

      .service_icon {
        font-size: 2.2857rem;
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
        border-radius: 50%;
        color: var(--primary-color);
        background: var(--primary-bg);
        text-align: center;

        &.green_icon {
          color: var(--green-color);
          background: var(--green-color-bg);
        }

        &.blue_icon {
          color: var(--blue-color);
          background: var(--blue-color-bg);
        }
      }

      & h5 {
        font-weight: 600;
        color: var(--white-color);
        font-size: 1.7143rem;
        line-height: 2.4286rem;
      }

      & p {
        font-size: 1.2143rem;
        line-height: 2rem;
        color: var(--font-color);
        font-weight: 500;
      }

      .read {
        font-size: 1rem;
        font-weight: 700;
        color: var(--white-color);
        margin-top: 2.1429rem;
        display: inline-block;
        text-transform: capitalize;
      }
    }
  }
}

/* -------------------------------------------------------------------*/

.about-content {
  color: #525252;
  display: grid;
  font-size: 1rem;
  grid: 100px / auto auto;
  grid-gap: 90px;
  position: relative;
}

.about-paraLast {
  margin-left: -4%;
}

/* ---------------------------------------------------------------------- */

.first-container {
  width: 80%;
  height: 60%;
  -webkit-font-smoothing: antialiased;
  margin: 0;

}

.corp_Main_Pic {
  background-color: #d3d3d370;
}


.nav-container {
  height: 77px;
  width: 100%;
  position: fixed;
  color: black;

  letter-spacing: 0.025em;
  transition: background-color 0.2s linear;
  background: transparent;
  z-index: 1005;
  font-weight: 500;

  &.solid-nav {
    background-color: #ffffff !important;
    transition: background-color 0.2s linear;
    color: #000000;
    font-weight: 500;
  }

  .nav-row {
    max-width: 88%;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 18px;

    .nav-logo {
      display: inline-block;
      font-weight: 900;
      margin-top: -3px;
    }

    .nav-controls {
      float: right;
    }
  }
}

.corp-lang {
  margin-top: -25px;
}

.corp-TryForFree {
  margin-left: 20px;
}

button.p-button.p-component.corp-btn-tryfree {
  background-color: #ff5f00;
  border: 1px solid #ff5f00;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  margin-top: 5px;
}

button.p-button.p-component.corp-btn-tryfree:hover {
  background-color: rgb(229, 90, 90);
  border-radius: 20px;
  border: none;
  margin-top: 5px;
}

button.p-button.p-component.corp-btn-starts {
  padding: 1.2rem 2.2rem;
  /* background-color: #ff5f00; */
  background-color: var(--ps-orange);
  border: 1px solid var(--ps-orange);
  font-size: 25px;
  color: #ffffff;
  line-height: 30px;
  border-radius: 35px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  font-weight: 600;
}

button.p-button.p-component.corp-btn-starts:hover {
  background-color: var(--ps-orange-hover);
  border: 1px solid var(--ps-orange-hover);
  color: #ffffff;
}

@media screen and (max-width: 1920px) {
  .hero-content {
    top: 39%;
    text-align: center;
    width: 32%;
    margin-left: 10%;
  }

  .hero-btns {
    margin-top: 10px;
    margin-left: 27px;
  }

  #cor-service-section #cor-paragraph {
    width: 70%;
  }

  #cor-service-section {
    min-height: 675px;
  }


  .corp-price-tag-color {
    color: red;
    text-align: center;
    margin: 0% 0% 0% 0%;
    font-size: 27px;
    font-weight: 600;
    margin-left: 37%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1748px) {

  .hero-content {
    width: 39%;
  }

  .hero-para-h3 {
    width: 74%;
  }

  .corp-price-tag-color {
    margin-left: 27%;
  }

  .hero-btns {
    margin-left: -38px;
  }
}

@media screen and (max-width: 1610px) {
  .hero-content {
    width: 49%;
    left: 40%;
  }

  .hero-para-h3 {
    width: 77%;
  }

}

@media screen and (max-width: 1570px) {
  .hero-para-h3 {
    width: 75%;
  }

  .corp-price-tag-color {
    margin: 2% 0% 0% 0%;
    margin-left: 28%;
  }

}

@media screen and (max-width: 1500px) {
  .hero-content {
    margin-top: 4%;
    margin-left: 6%;
  }

  .hero-para-h3 {
    width: 70%;
  }

  .hero-para-h3 {
    width: 78%;
  }

  .corp-price-tag-color {
    margin: 2% 0% 0% 0%;
    margin-left: 30%;
  }

}

@media screen and (max-width: 1450px) {
  .hero-content {
    top: 37%;
  }

  .hero-para-h3 {
    font-size: 20px;
  }

  .hero-btns {
    margin-top: 20px;
  }

  .cor-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  #cor-service-section h2 {
    margin-top: 2%;
  }

  #cor-paragraph {
    width: 30%;
  }
}

@media screen and (max-width: 1440px) {
  .corp-login {
    margin-top: 3px;
    margin-left: 10px;
  }

  #corp-tryfree-text {
    color: white !important;
    width: 60%;
    margin-left: 3%;
    margin-top: 12px;
  }

  .corp-price-tag-color {
    margin: 2% 0% 0% 0%;
    margin-left: 28%;
  }
}

@media screen and (max-width: 1430px) {
  .hero-content {
    width: 44%;
    left: 45%;
    top: 39%;
  }
}

@media screen and (max-width: 1386px) {
  .hero-content {
    top: 37%;
    width: 36%;
    left: 48%;
  }

  .digital-main-title {
    font-size: 32px;
  }

  .hero-para-h3 {
    font-size: 16px;
  }

  .corp-price-tag-color {
    margin-left: 17%;
  }
}

@media screen and (max-width: 1300px) {
  .hero-content {
    width: 41%;
    left: 46%;
    top: 38%;
  }
}

@media screen and (max-width: 1280px) {
  .hero-content {
    width: 43%;
    top: 33%;
  }

  .hero-para-h3 {
    font-size: 16px;
  }

  .hero-btns {
    margin-right: 3%;
    margin-top: 20px;
  }

  button.p-button.p-component.corp-btn-starts {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .hero-content {
    width: 47%;
    top: 35%;
    left: 43%;
  }
}

@media screen and (max-width: 1100px) {
  .hero-content {
    width: 49%;
    top: 32%;
    left: 44%;
  }
}



@media screen and (max-width: 1024px) {
  .hero-content {
    width: 50%;
    top: 30%;
    left: 43%;
    border-radius: 30px;
  }

  .corp_Main_Pic {
    background-color: #d3d3d370;
    margin-top: 7%;
  }

  .hero-para-h3 {
    font-size: 18px;
  }

  .hero-btns {
    margin-top: 11px;
  }

  /* 
  button.p-button.p-component.corp-btn-starts {
    padding: 0.5rem 1rem;
  } */
}

@media screen and (max-width: 1000px) {
  .hero-content {
    width: 50%;
    top: 27%;
    left: 43%;
    padding: 5%;
    border-radius: 30px;
  }

  .hero-para-h3 {
    font-size: 17px;
  }

  .hero-btns {
    margin-top: 11px;
  }
}

@media screen and (max-width: 991px) {
  .hero-content {
    width: 52%;
    left: 42%;
    border-radius: 30px;
  }
}

@media screen and (max-width: 970px) {
  .login-btn-user {
    margin-top: 20px;
  }
}

@media screen and (max-width: 958px) {
  .hero-content {
    width: 48%;
  }

  .hero-btns {
    margin-top: 5px;
    margin-left: 11%;
  }

}

@media screen and (max-width: 912px) {

  .hero-content {
    width: 56%;
    top: 25%;
    left: 37%;
  }

  .hero-btns {
    margin-right: 13%;
    margin-top: 4px;
  }
}

@media screen and (max-width: 853px) {
  .hero-content {
    /* width: 70%; */
    top: 29%;
    left: 37%;
    padding: 0rem 0rem 0rem 0rem;
  }

  .hero-btns {
    margin-right: 16%;
    margin-top: 4px;
  }
}

@media screen and (max-width: 834px) {
  /* .hero-content {
    width: 63%;
    top: 17%;
    left: 38%;
  } */

  .hero-btns {
    margin-right: 16%;
    margin-top: 4px;
  }

}

@media screen and (max-width: 830px) {
  .hero-container>h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container>p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }
}

@media screen and (max-width: 800px) {
  .first-container {
    width: 80%;
  }

  .hero-content {
    top: 25%;
  }

  .digital-main-title {
    font-size: 28px;
  }

  .hero-para-h3 {
    font-size: 16px;
  }

  .corp-price-tag-color {
    margin: 2% 17%;
  }
}

@media screen and (max-width: 750px) {
  .hero-content {
    margin-top: 7%;
  }

  .corp-price-tag-color {
    margin: 0% 12%;
  }

  .digital-main-title {
    font-size: 23px;
  }

  .corp_text_1 {
    display: none;
  }
}


@media screen and (max-width: 712px) {
  .hero-content {
    top: 20%;
  }

  .corp-price-tag-color {
    margin: 0% 4%;
    margin-left: 16%;
  }

}

@media screen and (max-width: 700px) {
  #cor-paragraph {
    width: 100%;
    margin-bottom: 40px;
  }

  .cor-cards {
    margin-top: -30px;
  }
}

@media screen and (max-width: 640px) {
  .hero-content {
    top: 22%;
  }

  .corp-price-tag-color {
    font-size: 23px;
    margin-left: 5%;
  }

  button.p-button.p-component.corp-btn-starts {
    padding: 0.4rem 1.0rem;
  }
}

@media screen and (max-width: 625px) {

  .hero-content {
    top: 21%;
  }

  .corp-price-tag-color {
    font-size: 23px;
    margin-left: 14%;
  }

  button.p-button.p-component.corp-btn-starts {
    padding: 0.4rem 1.0rem;
    margin-left: -19%;
  }

  .hero-btns {
    margin-right: 4%;
    margin-top: 4px;
  }

}

@media screen and (max-width: 600px) {
  .first-container {
    width: 80%;
  }

  .hero-content {
    margin-top: 7%;
  }



}

@media screen and (max-width: 590px) {
  .hero-content {
    margin-top: 14%;
  }

}

@media screen and (max-width: 540px) {
  p.hero-para {
    margin-top: 7%;
    margin-left: 11%;
    font-size: small;
  }

  .hero-content {
    margin-top: 10%;
  }

  .hero-para-h3 {
    font-size: 34px;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
  }


  button.p-button.p-component.corp-btn-starts {
    padding: 0.2rem 0.8rem;
  }

  .hero-btns {
    margin-right: 2%;
    margin-top: -2px;
  }

}

@media screen and (max-width: 522px) {
  .hero-content {
    margin-top: 9%;
  }

  button.p-button.p-component.corp-btn-starts {
    margin-left: 1%;
    width: 100%;
  }

}


@media screen and (max-width: 510px) {
  .hero-content {
    margin-top: 10%;
  }
}

@media screen and (max-width: 480px) {

  .first-container {
    width: 100%;
  }

  .hero-content {
    top: 37%;
    left: 31%;
  }

  .digital-main-title {
    font-size: 20px;
  }

  #cor-service-section h2 {
    margin-top: 3%;
  }

  .cor-cards .cor-card {
    height: 350px;
    width: 350px;
  }

  .corp-price-tag-color {
    font-size: 20px;
    margin-left: 12%;
  }

  .hero-btns {
    margin-right: 14%;
    margin-top: -2px;
  }

  button.p-button.p-component.corp-btn-starts {
    margin-left: 1%;
    width: 100%;
    font-size: 16px;
  }
}

@media screen and (max-width: 474px) {
  .hero-content {
    top: 38%;
    left: 31%;
  }
}

@media screen and (max-width: 465px) {

  .hero-content {
    top: 36%;
    left: 29%;
  }

  .corp-price-tag-color {
    font-size: 21px;
    margin-left: 10%;
  }

  .hero-btns {
    margin-right: 13%;
    margin-top: -2px;
  }

  button.p-button.p-component.corp-btn-starts {
    margin-left: 1%;
    width: 100%;
    font-size: 17px;
  }
}

@media screen and (max-width: 430px) {
  .hero-content {
    top: 30%;
    left: 28%;
  }

  .corp_text_1 {
    font-size: 16px;
  }

  .corp-price-tag-color {
    font-size: 16px;
    margin-left: 11%;
  }


  button.p-button.p-component.corp-btn-starts {
    font-size: 15px;
  }

  .home-contact-title {
    font-size: 1.5rem;
  }

  .hero-btns {
    margin-right: 20%;
    margin-top: -2px;
  }

  button.p-button.p-component.corp-btn-starts {
    margin-left: 1%;
    width: 100%;
    font-size: 18px;
  }

}

@media screen and (max-width: 414px) {

  .hero-content {
    top: 31.5%;
    left: 28%;
  }

  .corp_text_1 {
    font-size: 15px;
  }

  .corp-price-tag-color {
    font-size: 15px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 15px;
  }

  .hero-btns {
    margin-right: 25%;
    margin-top: -2px;
  }

}


@media screen and (max-width: 390px) {
  .hero-content {
    top: 30%;
    left: 29%;
  }

  .hero-para-h3 {
    font-size: 26px;
  }


  .corp-price-tag-color {
    font-size: 16px;
  }

  .hero-btns {
    margin-right: 16%;
    margin-top: 1px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 14px;
  }

  .home-contact-title {
    font-size: 1.7rem;
  }

  .contact-us-form-inside {
    padding: 15px;
  }
}

@media screen and (max-width: 375px) {
  .first-container {
    width: 100%;
    height: 70%;
  }

  .hero-content {
    top: 32%;
  }

  .digital-main-title {
    font-size: 19px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 14px;
  }

  .cor-cards .cor-card {
    height: 500px;
    width: 300px;
  }

  .services-list {
    font-size: 15px;
    margin-bottom: -7%;
  }
}

@media screen and (max-width: 360px) {
  .first-container {
    width: 100%;
    height: 70%;
  }

  .hero-content {
    top: 29%;
  }

  .hero-para-h3 {
    font-size: 26px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 14px;
  }

}


@media screen and (max-width: 344px) {
  .first-container {
    width: 100%;
    height: 70%;
    margin-top: 13%;
    margin-left: -5%;
  }

  .hero-content {
    top: 30.5%;
  }

  .hero-para-h3 {
    font-size: 26px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 14px;
  }

}


@media screen and (max-width: 329px) {
  .hero-content {
    top: 28%;
    left: 37%;
  }
}

@media screen and (max-width: 320px) {
  .hero-content {
    top: 27%;
    left: 36%;
  }

  .corp-price-tag-color {
    font-size: 14px;
  }


  .services-list {
    font-size: 14px;
    margin-bottom: -7%;
  }

  .contact-Name-User {
    width: 238px;
  }

  .contact-Email-User {
    width: 238px;
  }

  .contact-ContactNo-User {
    width: 238px;
  }

  .contact-Message-User {
    width: 238px;
  }
}

@media screen and (max-width: 315px) {
  .first-container {
    width: 100%;
    height: 70%;
  }

  .hero-content {
    top: 27%;
    left: 37%;
  }

  .hero-para-h3 {
    font-size: 26px;
  }

  button.p-button.p-component.corp-btn-starts {
    font-size: 14px;
  }

  .cor-cards .cor-card {
    height: 500px;
    width: 250px;
  }
}

@media screen and (max-width: 300px) {
  .hero-content {
    top: 25%;
  }


  .digital-main-title {
    font-size: 19px;
  }

  .corp-price-tag-color {
    font-size: 13px;
  }

  .contact-Name-User {
    width: 220px;
  }

  .contact-Email-User {
    width: 220px;
  }

  .contact-ContactNo-User {
    width: 220px;
  }

  .contact-Message-User {
    width: 220px;
  }
}

@media screen and (max-width: 280px) {

  .hero-content {
    top: 23%;
  }

  .footer-subscription-text {
    margin-left: -20px;
    margin-top: 14px;
    margin-bottom: 0rem;
    cursor: default;
  }

  .corp-price-tag-color {
    font-size: 12px;
  }

  .p-scrolltop {
    position: fixed;
    bottom: 37px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
  }
}

@media screen and (max-width: 260px) {
  .hero-content {
    top: 17%;
  }

  .digital-main-title {
    font-size: 17px;
  }

  .hero-btns {
    margin-right: 11%;
    margin-top: 1px;
  }

  .corp-price-tag-color {
    font-size: 11px;
  }
}


.services-section>h2 {
  text-align: center;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
}

.service-item {
  flex: 1 1 300px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.service-item h3 {
  margin-top: 0;
}

.service-item p {
  margin-bottom: 0;
}

#price-policy-div {
  background-color: white;
}

.services-section {
  /* padding: 50px; */
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #c9cfd7;
}

.services-section>h2 {
  text-align: center;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
}

.service-item h3 {
  margin-top: 0;
}

.service-item p {
  margin-bottom: 0;
}

.about-content>h5 {
  text-align: center;
}

.about-service {
  margin: 3%;
  width: 95%;
  text-align: center;
}

.about-service h4 {
  text-align: center;
  padding-bottom: 2%;
}

.about-service-paraDiv {
  padding: 1% 8% 1% 8%;
}
.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;

}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.faq-section {
  display: flex;
  padding: 8% 5%;

}

.image-class,
.image-class-div {
  width: 85%;
  height: 92%;
}

@media screen and (max-width: 960px) {
  .faq-section {
    width: 100%;
  }

  .faqs {
    height: 50%;
  }

  .image-class-div {
    margin-top: 10%;
  }

  .faq-div-home {
    height: 80%;
  }
}

@media screen and (max-width: 768px) {
  .faq-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .image-class-div {
    margin-left: 12%;
    margin-top: 0%;
  }

  .faqs .faq .faq-question {
    font-size: 17px;
  }
}

@media screen and (max-width: 280px) {
  .faqs .faq .faq-question {
    position: relative;
    font-size: 15px;
    padding-right: 40px;
    transition: all 0.4s ease;
  }

  .faqs .faq .faq-answer {
    font-size: 15px;
  }

  .faq-section {
    display: flex;
    padding: 1% 0%;
  }
}
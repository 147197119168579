.contact-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-contact-form {
  max-width: 820px;
  background-color: #5f82ae;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact-form {
  background-color: #868c95;
  position: relative;
  z-index: 0;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-us-form {
  padding: 66px;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.contact-us-form-inside {
  padding: 20px;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.home-contact-title {
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

button.p-button.p-component.contact-message-submit {
  background: #1c7ad4;
  color: white;
  border-radius: 19px;
  font-weight: 600;
  border: 1px solid white;
}

button.p-button.p-component.contact-message-submit:hover {
  background: white;
  color: #1c7ad4;
  border-radius: 19px;
  font-weight: 600;
  border: 1px solid white;
}

.contact-Name-User {
  width: 370px;
  height: 40px;
  border-radius: 10px;
  border: #fff;
}

.contact-Email-User {
  width: 370px;
  height: 40px;
  border-radius: 10px;
  border: #fff;
}

.contact-ContactNo-User {
  width: 370px;
  height: 40px;
  border-radius: 10px;
  border: #fff;
}

.contact-Message-User {
  width: 370px;
  height: 160px;
  border-radius: 10px;
  border: #fff;
}

.contact-message-submit {
  width: 150px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid var(--ps-blue);
  color: black;
  font-weight: 500;
}


.contact-file-upload {
  color: white;
}

.contact-faq-whole {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #9eb0c8;
}

.contact_label_choosefile {
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: -32%;
}

@media (max-width: 1024px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 77px;
  }

  .contact-container {
    min-height: 62vh;
  }

  .contact-faq-whole {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 912px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 77px;
  }

  .contact-container {
    min-height: 62vh;
  }
}

@media (max-width: 850px) {
  .our-contact-form {
    grid-template-columns: 1fr;
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }
}

@media (max-width: 820px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 37px;
  }

  .contact-container {
    min-height: 62vh;
  }
}

@media (max-width: 540px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 29px;
  }

  .contact-container {
    min-height: 93vh;
  }
}

@media (max-width: 480px) {
  .home-contact-title {
    font-size: 1.5rem;
  }

  .contact-us-form {
    padding: 29px;
  }

  .contact-container {
    min-height: 100vh;
  }
}

@media (max-width: 430px) {
  .home-contact-title {
    font-size: 1rem;
  }

  .contact-us-form {
    padding: 51px 6px;
  }
}

@media (max-width: 390px) {
  .home-contact-title {
    font-size: 1rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-container {
    padding: 3% 2% 17%;
  }

  .contact-Name-User {
    width: 282px;
  }

  .contact-Email-User {
    width: 282px;
  }

  .contact-ContactNo-User {
    width: 282px;
  }

  .contact-Message-User {
    width: 291px;
    height: 140px;
  }

  .contact_label_choosefile {
    margin-left: -20%;
  }
}

@media (max-width: 375px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 28px 0px 17% 0%;
  }

  .contact-Message-User {
    width: 287px;
  }
}

@media (max-width: 360px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-Name-User {
    width: 270px;
  }

  .contact-Email-User {
    width: 270px;
  }

  .contact-ContactNo-User {
    width: 270px;
  }

  .contact-Message-User {
    width: 270px;
  }
}

@media (max-width: 340px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-Name-User {
    width: 250px;
  }

  .contact-Email-User {
    width: 250px;
  }

  .contact-ContactNo-User {
    width: 250px;
  }

  .contact-Message-User {
    width: 250px;
  }

  .contact_label_choosefile {
    margin-left: -9%;
  }

}

@media (max-width: 315px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-Name-User {
    width: 230px;
  }

  .contact-Email-User {
    width: 230px;
  }

  .contact-ContactNo-User {
    width: 230px;
  }

  .contact-Message-User {
    width: 230px;
  }
}

@media (max-width: 290px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-Name-User {
    width: 200px;
  }

  .contact-Email-User {
    width: 200px;
  }

  .contact-ContactNo-User {
    width: 200px;
  }

  .contact-Message-User {
    width: 200px;
  }

  .contact_label_choosefile {
    margin-left: -1%;
  }

}

@media (max-width: 280px) {
  .home-contact-title {
    font-size: 2rem;
  }

  .contact-us-form {
    padding: 21px 3px 15% 0%;
  }

  .contact-Name-User {
    width: 200px;
  }

  .contact-Email-User {
    width: 200px;
  }

  .contact-ContactNo-User {
    width: 200px;
  }

  .contact-Message-User {
    width: 200px;
  }
}

.contactUsMaxUploadSize {
  text-align: left;
  padding-left: 9px;
  font-size: 12px;
  color: white;
  margin-top: 5px;
}

.p-toast.p-component.p-toast-top-right.p-ripple-disabled {
  margin-top: 111px !important;
}

.contactUsEmailLastLine {
  color: rgb(8, 0, 0);
}

#email_contact_text {
  color: white;
  font-style: italic;
}